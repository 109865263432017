import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const RedirectComponent = () => {
  const location = useLocation();

  useEffect(() => {
   const targetBaseUrl = 'https://map-my-sona.web.app'; // Change this to the target site
    
   console.log(location.pathname,"----",location.search)

    let newPath=location.pathname.split("/")

    let client=newPath[2]

    let clientbasedURL=""

    

    console.log("This is your client ",client)

    for(let i of newPath)
    {
          console.log(i)
    }
    let index=(newPath.length)-1
    console.log("Path --  -",index)

    console.log("Sona",newPath[index])

    let s="/"+newPath[index]

    console.log("WEEE",client)

    switch(client)
    {
      case "tms000001_sct":
        { clientbasedURL="http://sona.trackmyservices.in/user-complaints/"+newPath[index]
        break
      }
       case "tms000008_nfh":
        {         clientbasedURL="http://nfh.trackmyservices.in/user-complaints/"+newPath[index]
          break
        }

       case "tms000006_tpt":
        {
         clientbasedURL="http://tpt.trackmyservices.in/user-complaints/"+newPath[index]
         break}

       case "tms000002_tan":
        {
         clientbasedURL="http://tan.trackmyservices.in/user-complaints/"+newPath[index]
         break}

         case "tms000006_st":
          {
          clientbasedURL="http://st.trackmyservices.in/user-complaints/"+newPath[index]
          break}
      
         case "tms000004_smcny":
          {
            clientbasedURL="http://smcny.trackmyservices.in/user-complaints/"+newPath[index]
            break}

        case "tms000005_arts":
          {
          clientbasedURL="http://arts.trackmyservices.in/user-complaints/"+newPath[index]
          break}

    }

    console.log("Client based URL --",clientbasedURL)



   // const newUrl = `${targetBaseUrl}${location.pathname}${location.search}`;

   const newUrl=`${targetBaseUrl}${s}${location.search}`;

   console.log("New URL ",newUrl)
 // window.location.href = newUrl;

 window.location.href=clientbasedURL

}, [location]);

  return <p>TMS 3.0 is loadding ...</p>; 
};

export default RedirectComponent;

